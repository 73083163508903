<template>
	<div class="d-flex justify-content-center mb-2 mr-1">
		<form class="bg-white shadow-md rounded px-2 mr-2" @submit.prevent>
			<div class="d-flex align-items-center">
				<v-date-picker v-model="range" mode="dateTime" :minute-increment="5" timezone="utc" :locale="locale" is-range is24hr :update-on-input="false">
					<template v-slot="{ inputValue, inputEvents, isDragging }">
						<div class="d-flex align-items-center justify-content-center py-2">
							<div class="picker-container">
								<svg
									class="mx-2 iconsize"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
								</svg>
								<input
									class="pl-12 pr-1 py-1 bg-gray-100 border rounded w-full"
									:class="isDragging ? 'text-gray-600' : 'text-gray-900'"
									:value="inputValue.start"
									v-on="inputEvents.start"
								/>
							</div>
							<div class="picker-container ml-2">
								<svg
									class="mx-2 iconsize"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
								</svg>
								<input
									class="pl-12 pr-1 py-1 bg-gray-100 border rounded w-full"
									:class="isDragging ? 'text-gray-600' : 'text-gray-900'"
									:value="inputValue.end"
									v-on="inputEvents.end"
								/>
							</div>
						</div>
					</template>
				</v-date-picker>
				<div style="text-align: right">
					<button class="btn btn-sm btn-primary mx-2" @click="changeDate" :disabled="!loaded">Apply <b-spinner v-if="!loaded" small /></button>
					<button class="btn btn-sm btn-danger" @click="removeDate">Clear</button>
				</div>
			</div>
		</form>
		<div class="d-flex justify-content-end">
			<DateRangeButton text="1 Day" @button-click="dateButton(1)" :loaded="loaded" />
			<DateRangeButton text="3 Days" @button-click="dateButton(3)" :loaded="loaded" />
			<DateRangeButton text="7 Days" @button-click="dateButton(7)" :loaded="loaded" />
			<DateRangeButton text="This Month" @button-click="dateButton(30)" :loaded="loaded" />
			<DateRangeButton text="Last Month" @button-click="dateButton(-30)" :loaded="loaded" />
			<!-- <DateRangeButton text="90 Days" @button-click="dateButton(90)" :loaded="loaded" /> -->
		</div>
	</div>
</template>

<script>
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';
import { ENABLE_DATERANGE, REMOVE_DATERANGE } from '@/core/services/store/daterange.module';

export default {
	props: ['loaded'],
	components: {
		DateRangeButton: () => import('@/view/content/widgets/components/DateRangeButton.vue'),
		VDatePicker,
	},
	computed: {
		locale() {
			return navigator.language || 'en-US';
		},
	},
	data() {
		return {
			range: {
				start: new Date(new Date().setDate(new Date().getDate() - 1)),
				end: new Date(),
			},
		};
	},
	methods: {
		changeDate() {
			this.$store.dispatch(ENABLE_DATERANGE, this.range).then(() => {
				console.log('updating date...')
				this.$emit('update');
			});
		},
		removeDate() {
			this.$store.dispatch(REMOVE_DATERANGE).then(() => {
				console.log('removing date...')
				this.$emit('update');
			});
		},
		dateButton(days) {
			switch (days) {
				case 1:
					// this.range = { start: new Date(new Date().setDate(new Date().getDate() - 1)), end: new Date()}
					this.range.start = new Date(new Date().setDate(new Date().getDate() - 1));
					this.range.end = new Date();
					break;
				case 3:
					// this.range = { start: new Date(new Date().setDate(new Date().getDate() - 3)), end: new Date()}
					this.range.start = new Date(new Date().setDate(new Date().getDate() - 3));
					this.range.end = new Date();
					break;
				case 7:
					// this.range = { start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date()}
					this.range.start = new Date(new Date().setDate(new Date().getDate() - 7));
					this.range.end = new Date();
					break;
				case 90:
					// this.range = { start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date()}
					this.range.start = new Date(new Date().setDate(new Date().getDate() - 90));
					this.range.end = new Date();
					break;
				case 30:
					var date = new Date();
					var offset = (date.getTimezoneOffset())/60;
					// this.range = { start: new Date(date.getFullYear(), date.getMonth(), 1, -offset), end: new Date()}
					this.range.start = new Date(date.getFullYear(), date.getMonth(), 1, -offset);
					this.range.end = new Date();
					break;
				case -30:
					var date = new Date();
					var offset = (date.getTimezoneOffset())/60;
					// this.range = { start: new Date(date.getFullYear(), date.getMonth() - 1, 1, -offset), end: new Date(date.getFullYear(),  date.getMonth(), 1, -offset, 0, -1)}
					this.range.start = new Date(date.getFullYear(), date.getMonth() - 1, 1, -offset);
     				this.range.end = new Date(date.getFullYear(),  date.getMonth(), 1, -offset, 0, -1);

					break;
			}
			this.changeDate();
		},
	},

	beforeDestroy() {
		this.removeDate();
	},
};
</script>

<style>
.iconsize {
	width: 24px;
	height: 24px;
}
.picker-container {
	position: relative;
	cursor: pointer;
	height: 30px;
}
.picker-container svg {
	position: absolute;
	left: 0;
	top: 2px;
}
.picker-container input {
	height: 100%;
}
</style>
